import React, { createContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import useMessage from "src/hooks/useMessage";
import { DataContextType } from "src/types/dataContextTypes";
import api from "src/utils/apiConfig";

const DataContext = createContext<DataContextType>({} as DataContextType);

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const { forceLogoutDisabledUser } = useAuth();
    const { showInfo } = useMessage();

    const handleAPIError = async (error: any) => {
        const errorCode = error?.response?.status;
        const errorMessage = error?.response?.data?.message;

        console.log(`${errorCode} Error with Message ${errorMessage} Received`);

        if (!errorMessage) {
            throw "Unhandled Server Error";
        }

        if (errorMessage === "Please complete your profile details") {
            showInfo("Please complete your profile details");
            navigate("/profileSettings");
        }

        if (errorMessage === "Your account is disabled") {
            forceLogoutDisabledUser();
        }

        // No Error Handling Written Throwing to be handled
        throw errorMessage;
    };

    const fetchData = async (url: string) => {
        try {
            const response = await api.get(url, {});
            return response.data;
        } catch (error: any) {
            await handleAPIError(error);
        }
    };

    const postData = async (url: string, data: any) => {
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (error: any) {
            await handleAPIError(error);
        }
    };

    const patchData = async (url: string, data: any) => {
        try {
            const response = await api.patch(url, data, {});
            return response.data;
        } catch (error: any) {
            await handleAPIError(error);
        }
    };

    const deleteData = async (url: string) => {
        try {
            const response = await api.delete(url, {});
            return response.data;
        } catch (error: any) {
            await handleAPIError(error);
        }
    };

    return (
        <DataContext.Provider
            value={{ fetchData, postData, patchData, deleteData }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
