import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { BsPersonCircle } from "react-icons/bs";
import useAuth from "src/hooks/useAuth";
import NotificationButton from "../messages/NotificationButton";
import MessagesPopup from "../messages/MessagesPopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
    avatarButton,
    navContentStyle,
    navToggleIcon,
} from "../../assets/styles/insideNavbarStyles";
import {
    headerBackgroundStyle,
    headerContentStyle,
} from "./LandingHeader.style";
import autoBackorderLogo1 from "../../assets/images/headerHorizontalLogo.webp";
import autoBackorderLogo2 from "../../assets/images/headerVerticalLogo.webp";
import { headerHeight } from "../SharedStyles";

export const NavbarAvatarButtons = () => {
    const [menuActive, setMenuActive] = useState<boolean>(false);
    const auth = useAuth();
    const handleClose = () => setMenuActive(false);

    return (
        <Box
            sx={{
                position: "relative",
                display: { xs: "none", md: "inline-block" },
            }}
        >
            <Box
                onClick={() => {
                    setMenuActive((menuActive) => !menuActive);
                }}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#EFD016",
                    cursor: "pointer",
                }}
            >
                <BsPersonCircle style={{ fontSize: 20, color: "#EFD016" }} />
                <ArrowDropDownIcon />
            </Box>
            <Modal
                open={menuActive}
                onClose={handleClose}
                sx={{
                    top: 25,
                    right: 10,
                    "& .MuiBackdrop-root": {
                        opacity: "0 !important",
                    },
                }}
            >
                <Box sx={avatarButton.menuBox}>
                    <NavLink
                        to="/submissions"
                        style={({ isActive }) => ({
                            color: isActive ? "#EFD016" : "white",
                            textDecoration: "none",
                            cursor: "pointer",
                        })}
                    >
                        <Typography
                            onClick={() => {
                                setMenuActive((menuActive) => !menuActive);
                            }}
                            sx={avatarButton.menuItem}
                        >
                            Submission History
                        </Typography>
                    </NavLink>
                    <hr
                        style={{
                            borderColor: "#ffff0021",
                            borderWidth: "0.2px",
                        }}
                    />
                    <NavLink
                        to="/profileSettings"
                        style={({ isActive }) => ({
                            color: isActive ? "#EFD016" : "white",
                            textDecoration: "none",
                            cursor: "pointer",
                        })}
                    >
                        <Typography
                            onClick={() => {
                                setMenuActive((menuActive) => !menuActive);
                            }}
                            sx={avatarButton.menuItem}
                        >
                            Profile Settings
                        </Typography>
                    </NavLink>
                    <hr
                        style={{
                            borderColor: "#ffff0021",
                            borderWidth: "0.2px",
                        }}
                    />
                    <Typography
                        id="logout-button"
                        sx={{
                            fontSize: {
                                xs: "12px",
                                md: "14px",
                                cursor: "pointer",
                            },
                            p: 1,
                            color: "red",
                        }}
                        onClick={() => {
                            auth.logout();
                        }}
                    >
                        Logout
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
};

export const NavButton = ({
    label,
    link,
    styles,
    setNavbarToggle,
    disabled = false,
}: {
    label: string;
    link: string;
    styles?: any;
    setNavbarToggle: any;
    disabled?: boolean;
}) => {
    return (
        <Box
            sx={{
                margin: { xs: 0, md: "0 15px" },
                py: { xs: 2, md: 0 },
                px: { xs: 2, md: 0 },
                display: "flex",
                borderBottom: { xs: 1, md: 0 },
                borderColor: { xs: "#ffff0021", md: "black" },
                ...styles,
            }}
        >
            {disabled ? (
                <Box
                    style={{
                        color: "white",
                        textDecoration: "none",
                        cursor: "pointer",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: "12px", md: "15px" },
                            "&:hover": {
                                color: "#EFD016",
                            },
                        }}
                    >
                        {label}
                    </Typography>
                </Box>
            ) : (
                <NavLink
                    to={link}
                    style={({ isActive }) => ({
                        color: isActive ? "#EFD016" : "white",
                        textDecoration: "none",
                        cursor: "pointer",
                    })}
                    onClick={() => {
                        setNavbarToggle(false);
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: "12px", md: "15px" },
                            "&:hover": {
                                color: "#EFD016",
                            },
                        }}
                    >
                        {label}
                    </Typography>
                </NavLink>
            )}
        </Box>
    );
};

export const NavIcon = ({ isOpen }: { isOpen: boolean }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
                sx={{
                    ...navToggleIcon.bar,
                    transform: isOpen
                        ? "rotateZ(45deg) translate3D(5px,5px,0)"
                        : "rotateZ(0)",
                }}
            ></Box>
            <Box
                sx={{
                    ...navToggleIcon.bar,
                    opacity: isOpen ? 0 : 1,
                }}
            ></Box>
            <Box
                sx={{
                    ...navToggleIcon.bar,
                    transform: isOpen
                        ? "rotateZ(-45deg) translate3D(5px,-5px,0)"
                        : "rotateZ(0)",
                }}
            ></Box>
        </Box>
    );
};

const InsideNavbar = () => {
    const auth = useAuth();
    const navigate = useNavigate();

    const [navbarToggle, setNavbarToggle] = useState<boolean>(false);
    const [notifyToggle, setNotifyToggle] = useState<boolean>(false);
    const [unreadList, setUnreadList] = useState<number[]>([]);

    const handleLogoClick = () => {
        if (window.location.pathname === "/dashboard/preBids") {
            window.location.reload();
        } else {
            navigate("/dashboard/preBids");
        }
    };

    return (
        <Box
            sx={{
                ...headerBackgroundStyle,
                display: "flex",
                alignItems: "center",
                height: headerHeight,
            }}
        >
            <Box
                sx={{
                    ...navContentStyle.outerBox,
                    paddingX: headerContentStyle.paddingX,
                }}
            >
                <MessagesPopup
                    open={notifyToggle}
                    setOpen={setNotifyToggle}
                    unreadList={unreadList}
                    setUnreadList={setUnreadList}
                />
                <Box display={{ xs: "none", sm: "flex" }}>
                    <img
                        id="inside-logo-horizontal"
                        src={autoBackorderLogo1}
                        alt="Logo"
                        loading="lazy"
                        style={{
                            height: "40px",
                            cursor: "pointer",
                        }}
                        onClick={handleLogoClick}
                    />
                </Box>
                <Box display={{ xs: "flex", sm: "none" }}>
                    <img
                        id="inside-logo-vertical"
                        src={autoBackorderLogo2}
                        alt="Logo"
                        loading="lazy"
                        style={{
                            height: "35px",
                            cursor: "pointer",
                        }}
                        onClick={handleLogoClick}
                    />
                </Box>
                <Box
                    sx={{
                        ...navContentStyle.navLinkHolder,
                        top: { xs: navbarToggle ? "90px" : "-500px", md: "0" },
                    }}
                >
                    <NavButton
                        label="Home"
                        link="/"
                        setNavbarToggle={setNavbarToggle}
                        styles={{ display: "inline-box" }}
                    />
                    <NavButton
                        label="Dashboard"
                        link="/dashboard/preBids"
                        setNavbarToggle={setNavbarToggle}
                        styles={{ display: "inline-box" }}
                    />
                    <NavButton
                        label="Balance"
                        link="/balance"
                        setNavbarToggle={setNavbarToggle}
                        styles={{ display: "inline-box" }}
                    />
                    <NavButton
                        label="Submission History"
                        link="/submissions"
                        setNavbarToggle={setNavbarToggle}
                        styles={{ display: { xs: "inline-box", md: "none" } }}
                    />
                    <NavButton
                        label="Profile Settings"
                        link="/profileSettings"
                        setNavbarToggle={setNavbarToggle}
                        styles={{ display: { xs: "inline-box", md: "none" } }}
                    />
                    <Box
                        sx={{
                            margin: { xs: 0, md: "0 15px" },
                            py: { xs: 2, md: 0 },
                            px: { xs: 2, md: 0 },
                            borderBottom: { xs: 1, md: 0 },
                            borderColor: { xs: "#ffff0021", md: "black" },
                            display: { xs: "inline-box", md: "none" },
                        }}
                        onClick={() => {
                            auth.logout();
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: { xs: "12px", md: "15px" },
                                color: "red",
                                cursor: "pointer",
                            }}
                        >
                            Logout
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: "none", md: "flex" },
                            flexDirection: "row",
                            justifyContent: "space-around",
                        }}
                    >
                        <NotificationButton
                            setOpen={setNotifyToggle}
                            unreadList={unreadList}
                            disabled={false}
                        />
                    </Box>
                    <NavbarAvatarButtons />
                </Box>
                <Stack
                    sx={{
                        display: { xs: "flex", md: "none" },
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center",
                    }}
                >
                    <NotificationButton
                        setOpen={setNotifyToggle}
                        unreadList={unreadList}
                        disabled={false}
                    />
                    <Button
                        onClick={() => {
                            setNavbarToggle((navbarToggle) => !navbarToggle);
                        }}
                        sx={{
                            display: { xs: "flex", md: "none" },
                            flexDirection: "row",
                            justifyContent: "space-around",
                        }}
                    >
                        <NavIcon isOpen={navbarToggle} />
                    </Button>
                </Stack>
            </Box>
        </Box>
    );
};

export default InsideNavbar;
