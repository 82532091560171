import jwtDecode from "jwt-decode";
import React, {
    createContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import authReducer, { authStates } from "src/reducers/authReducer";
import { AuthContextType, AuthState } from "src/types/authTypes";
import api from "src/utils/apiConfig";
import {
    getToken,
    handleTokenStorage,
    refreshAuthToken,
    TokenTypeEnum,
    verifyToken,
} from "src/utils/jwtFunctions";
import { defaultRoute } from "./CurrentRouteContext";
import DisabledPopup from "src/components/popup/disabledPopup";

const initialState: AuthState = {
    user: null,
    isLogged: false,
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();

    const [state, dispatch] = useReducer(authReducer, initialState);

    // State To Disable User on Admin or Auc Processor Action
    const [isUserDisabled, setIsUserDisabled] = useState<boolean>(false);

    async function loadUserFromMemory() {
        let authToken = getToken(TokenTypeEnum.AUTH);

        if (authToken) {
            const isAuthValid = verifyToken(authToken);

            if (!isAuthValid) {
                authToken = await refreshAuthToken();
            }

            if (authToken) {
                buildStateFromToken(authToken);
            }
        }
    }

    async function buildStateFromToken(authToken: any) {
        try {
            const decoded: any = jwtDecode(authToken);

            if (!decoded) {
                throw new Error("Error decoding Auth Token");
            }

            if (!verifyToken(authToken)) {
                throw new Error("Token Expired");
            }

            if (decoded.isDisabled) {
                throw new Error("disabled");
            }

            dispatch({
                type: authStates.LOGIN,
                payload: {
                    user: {
                        email: decoded.email,
                        isDisabled: decoded.isDisabled,
                        username: decoded.username,
                        role: decoded.role,
                        isTrusted: decoded.isTrusted,
                        isCompleted: decoded.isCompleted,
                    },
                    isLogged: true,
                    authToken: authToken,
                },
            });

            const path = localStorage.getItem("currentRoute") || defaultRoute;
            navigate(path);
        } catch (error: any) {
            await logout();
        }
    }

    async function switchIsCompleted(isCompleted: boolean) {
        dispatch({
            type: authStates.SWITCH_COMPLETED,
            payload: {
                isCompleted,
            },
        });

        // Renewing Auth Token with correct completed guard
        if (isCompleted) {
            await refreshAuthToken();
        }
    }

    async function login(
        uname: string,
        password: string,
        shouldPersist: boolean,
    ) {
        const response = await api.post("/login", {
            username: uname,
            password,
            shouldPersist,
        });

        const { username, email, accessToken, refreshToken } = response.data;

        if (!accessToken) {
            throw new Error("Login error");
        }

        const decoded: any = jwtDecode(accessToken);

        if (!decoded || !decoded.exp) {
            throw new Error("Error in the Access Token");
        }

        if (decoded.isDisabled) {
            throw new Error("Your account is disabled");
        }

        handleTokenStorage("SET", TokenTypeEnum.AUTH, accessToken);
        handleTokenStorage("SET", TokenTypeEnum.REFRESH, refreshToken);
        localStorage.setItem("login", Date.now().toString());

        dispatch({
            type: authStates.LOGIN,
            payload: {
                user: {
                    username,
                    email,
                    isDisabled: decoded.isDisabled,
                    role: decoded.role,
                    isTrusted: decoded.isTrusted,
                    isCompleted: decoded.isCompleted,
                },
                isLogged: true,
            },
        });
    }

    async function clearAuthState() {
        handleTokenStorage("REM", TokenTypeEnum.AUTH);
        handleTokenStorage("REM", TokenTypeEnum.REFRESH);
        localStorage.setItem("logout", Date.now().toString());

        dispatch({
            type: authStates.LOGOUT,
        });
    }

    async function logout() {
        const refreshToken = getToken(TokenTypeEnum.REFRESH);

        if (refreshToken) {
            try {
                const logoutResponse = await api.post("/logout", {});

                if (logoutResponse && logoutResponse.data?.success) {
                    clearAuthState();
                    navigate("/login");
                }
            } catch (error) {
                console.log("Error while logging out");
            }
        }
    }

    async function forceLogoutDisabledUser() {
        setIsUserDisabled(true);
        try {
            await new Promise((resolve) => setTimeout(resolve, 5000));
        } finally {
            setIsUserDisabled(false);
            await logout();
        }
    }

    // Auth Token refresh at expiry
    api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const errorMessage = error?.response?.data?.message ?? "";
            if (errorMessage === "Auth Token has expired") {
                try {
                    const newToken = await refreshAuthToken();
                    if (newToken) {
                        return api(error.config);
                    } else {
                        throw new Error("Refresh Failed");
                    }
                } catch (refreshError) {
                    clearAuthState();
                    window.location.reload();
                }
            } else {
                return Promise.reject(error);
            }
        },
    );

    // Loading State from Session at page refresh
    useEffect(() => {
        loadUserFromMemory();
    }, []);

    // Across Tab Logout watch
    useEffect(() => {
        const handleStorageChange = (event: any) => {
            if (event.key === "login") {
                console.log("User logged in in another tab");
                window.location.reload();
            } else if (event.key === "logout") {
                console.log("User logged out in another tab");
                window.location.reload();
            }
        };

        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    const authContextValue = useMemo(
        () => ({
            user: state.user,
            login,
            logout,
            isLogged: state.isLogged,
            switchIsCompleted,
            forceLogoutDisabledUser,
        }),
        [state.user, state.isLogged],
    );

    return (
        <AuthContext.Provider value={authContextValue}>
            <DisabledPopup openDisabledPopup={isUserDisabled} />
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;
