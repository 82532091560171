import { Suspense } from "react";
import LandingHeader from "../navigation/LandingHeader";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import { Grid } from "@mui/material";
import { contentWithoutAuthStyle, headerContainerStyle } from "../SharedStyles";
import PageLoader from "../common/PageLoader";

export const Layout = ({ showFooter }: { showFooter: boolean }) => (
    <Grid
        container
        style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
        }}
    >
        <Grid item xs={12} sx={headerContainerStyle}>
            <LandingHeader />
        </Grid>
        <Suspense fallback={<PageLoader />}>
            <Grid item xs={12} sx={contentWithoutAuthStyle}>
                <Outlet />
            </Grid>
            {showFooter && (
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            )}
        </Suspense>
    </Grid>
);
