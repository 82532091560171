import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import InputFieldWrapper from "src/components/registrantProfile/InputFieldWrapper";
import { regProfileStyles } from "./registrantProfileStyles";
import useFormikField from "../../hooks/useFormikField";
import { InputSectionTag } from "../../types/registrantProfileTypes";

const InputSection = ({
    tag,
    disabled = false,
    formik,
}: {
    tag: InputSectionTag;
    disabled?: boolean;
    formik: any;
}) => {
    const { getFieldName, getFieldValue, fieldHasErrors, getValidationError } =
        useFormikField(formik, tag);

    const renderField = ({
        fieldName,
        label,
        type = "text",
        fieldDisabled = disabled,
    }: {
        fieldName: string;
        label: string;
        type?: string;
        fieldDisabled?: boolean;
    }) => {
        return (
            <InputFieldWrapper
                label={label}
                hasWarning={fieldHasErrors(fieldName)}
                warning={getValidationError(fieldName)}
            >
                <TextField
                    name={getFieldName(fieldName)}
                    type={type}
                    value={getFieldValue(fieldName)}
                    onChange={formik.handleChange}
                    sx={regProfileStyles.textField}
                    inputProps={{
                        style: {
                            margin: 0,
                            padding: "10px 8px",
                        },
                    }}
                    disabled={fieldDisabled}
                />
            </InputFieldWrapper>
        );
    };

    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            paddingTop={2}
            paddingBottom={2}
        >
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "name",
                    label: "Name",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "company",
                    label: "Company",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "street",
                    label: "Street",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "city",
                    label: "City",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "state",
                    label: "State",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "country",
                    label: "Country",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "zip",
                    label: "Zip",
                })}
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                <InputFieldWrapper
                    label="Phone"
                    hasWarning={fieldHasErrors("phone")}
                    warning={getValidationError("phone")}
                >
                    <PhoneInput
                        id={getFieldName("phone")}
                        name={getFieldName("phone")}
                        className="phoneInput"
                        value={getFieldValue("phone")}
                        disabled={disabled}
                        onChange={(phoneNumber) => {
                            formik.setFieldValue(
                                getFieldName("phone"),
                                phoneNumber ?? "",
                            );
                        }}
                        numberInputProps={{
                            style: {
                                padding: "12px",
                                borderRadius: "15px",
                                borderColor: "#3A354180",
                                borderWidth: "1px",
                                backgroundColor: "transparent",
                                fontFamily: "'Inter', sans-serif",
                                fontSize: "15px",
                            },
                        }}
                    />
                </InputFieldWrapper>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
                {renderField({
                    fieldName: "email",
                    label: "Email",
                    fieldDisabled:
                        disabled || tag === InputSectionTag.OWNER_INFO,
                })}
            </Grid>
        </Grid>
    );
};

export default InputSection;
